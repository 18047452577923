/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { g as o } from "./_commonjsHelpers.js";
var r,
  t,
  n,
  a = {
    exports: {}
  };
r = a, t = function () {
  function o(o, t, a, f, e) {
    r(o, t, a || 0, f || o.length - 1, e || n);
  }
  function r(o, n, a, f, e) {
    for (; f > a;) {
      if (f - a > 600) {
        var s = f - a + 1,
          i = n - a + 1,
          h = Math.log(s),
          c = .5 * Math.exp(2 * h / 3),
          p = .5 * Math.sqrt(h * c * (s - c) / s) * (i - s / 2 < 0 ? -1 : 1);
        r(o, n, Math.max(a, Math.floor(n - i * c / s + p)), Math.min(f, Math.floor(n + (s - i) * c / s + p)), e);
      }
      var u = o[n],
        M = a,
        m = f;
      for (t(o, a, n), e(o[f], u) > 0 && t(o, a, f); M < m;) {
        for (t(o, M, m), M++, m--; e(o[M], u) < 0;) M++;
        for (; e(o[m], u) > 0;) m--;
      }
      0 === e(o[a], u) ? t(o, a, m) : t(o, ++m, f), m <= n && (a = m + 1), n <= m && (f = m - 1);
    }
  }
  function t(o, r, t) {
    var n = o[r];
    o[r] = o[t], o[t] = n;
  }
  function n(o, r) {
    return o < r ? -1 : o > r ? 1 : 0;
  }
  return o;
}, void 0 !== (n = t()) && (r.exports = n);
const f = o(a.exports);
export { f as q };